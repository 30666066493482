.logo-admin-container {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}

.login-form-admin-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-content: center;
  align-items: center;

  .login-form.login-form-admin {
    background-color: #f5f6fa;
    min-width: 30vw;
    padding: 40px;
    border-radius: 12px;

    .otp-qr-placeholder{
      padding-bottom: 40px;
    }

    .MuiTextField-root {
      margin-bottom: 40px;
      background-color: #ffffff;
      border-radius: 6px;
    }

    .MuiFormHelperText-root.Mui-error {
      margin-top: 8px;
    }

    .back-bottom-container {
      //min-width: 30vw;
      //margin-left: auto;
      //margin-right: auto;
      //padding: 20px 0;
      //border-radius: 12px;
      //text-align: right;

      .button-link{
        justify-content: flex-end;
        margin-top: 40px;
        &:hover{
          background-color: transparent;
        }

        .link{
          color: #626262;
          text-transform: lowercase;
          text-decoration: none;
        }
      }
    }
  }
}

.login-card {
  background-color: var(--color-primary);
  color: var(--color-primary-text);
  width: 60%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;

  &.warning {
    background-color: var(--color-warning);
    color: var(--color-warning-text);
  }
}



#root {
  main {
    background-color: #fff;
  }
  header svg {
    display: none;
  }
}