.input-form {
  display: flex;
  flex-direction: column;
  .stack-switch {
    padding-left: 16px;
    margin-top: 16px;
    margin-bottom: 16px;

    .label {
      line-height: 2.5
    }

  }
}

