.login-form-email-code {
  display: flex;
  justify-content: center;
  margin-bottom: 32px;

  .login-form-code-input {
    margin-bottom: 16px;
  }
}

.reset-login-form {
  //min-width: 30vw;
  width: 100%;
  padding: 40px;
  border-radius: 12px;

  .MuiTextField-root {
    margin-bottom: 40px;
    //background-color: #ffffff;
    border-radius: 6px;
  }
}

.login-card {
  color: #ffffff;
  //width: 60%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: 1024px) {
    width: 100%;
  }

  &.warning {
    background-color: var(--color-warning);
    color: var(--color-warning-text);
  }

  .resend-button-card {
    padding-bottom: 24px;
  }
}

.back-bottom-container {

  .button-link {
    justify-content: flex-end;
    margin-top: 40px;

    &:hover {
      background-color: transparent;
    }

    .link {
      color: #626262;
      text-transform: lowercase;
      text-decoration: none;
    }
  }
}