.register-form-admin {

  .register-form {
    width: 45vw;
    backdrop-filter: blur(1px);
    background-color: rgba(245, 246, 250, 0.9);
    padding-right: 32px;
    padding-bottom: 32px;
    border-radius: 12px;

    @media screen and (max-width: 1024px) {
      width: 90vw;
    }

    .MuiGrid-root {
      width: 100%;
      margin: auto;

      .MuiFormHelperText-root.Mui-error {
        margin-top: 8px;
      }

      @media screen and (max-width: 1024px) {
        .form-password-input .Mui-error {
          margin-bottom: 40px;
        }
      }


      .MuiInputBase-input {
        background-color: #ffffff;
        border-radius: 6px;
      }

      .MuiInputBase-multiline {
        background-color: #ffffff;
      }

      .form-register-button {
        display: flex;
        justify-content: space-between;
      }
    }

    .otp-qr-placeholder {
      display: flex;
      justify-content: center;
    }

  }

  .registration-card {
    background-color: var(--color-primary);
    color: var(--color-primary-text);
    width: 60%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;

    &.warning {
      background-color: var(--color-warning);
      color: var(--color-warning-text);
    }
  }
}