*div {
  outline: 0;
  box-sizing: border-box!important;
}
.MuiTypography-root .column-label, .column-label {
  text-align: center;
  font-size: 12px !important;
  font-weight: 600 !important;
  color: var(--color-secondary-text);
  text-transform: uppercase;
  letter-spacing: 1px;
}
.panel-header {
  width: 100%;
  padding: 10px 16px !important;
  background-color: var(--color-secondary-bg);
  text-transform: uppercase;
  .MuiCardHeader-title {
    line-height: 2rem;
    font-size: 14px;
    letter-spacing: 1px;
    font-weight: 700;
  }
}

.page-title-container {
  padding: 30px 24px 40px 5%;
  position: relative;
  top: -16px;
  left: -16px;
  width: calc(100% + 32px);
  background-color: #f6f7f9;
  
  @media(min-width: 1200px) {
    padding: 30px 16px 40px 5%;
    top: -24px;
    left: -24px;
    width: calc(100% + 48px);
  }
  h1 {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 10px;
  }
  h2 {
    font-size: 16px;
    font-weight: 500;
  }
}