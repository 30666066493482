.box-collapse {
  display: flex;
  cursor: pointer;
  justify-content: space-between; 
  align-items: center; 
  gap: 20px;
  margin: 30px 0 0 0;
  padding: 10px;
  background-color: #f6f7f9;
}

.box-collapse-in {
  padding: 10px;
  border: 1px solid #e7e9ed;
  border-top: 0
}

.box-collapse-in p {
  margin-bottom: 10px;
  font-size: 14px;
}

