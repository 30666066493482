.message-box {

  .message-item {
    background-color: #f5f6fa;
    margin-bottom: 16px;
    padding: 8px 16px;
    border-radius: 8px;

    .message-time{
      font-size: 12px;
      margin-bottom: 8px;
    }

    &.admin{
      background-color: rgba(0, 0, 0, 0.08);
    }
  }

  .left {
  }

  .right {
  }

}